<template>
  <base-section id="core-values">
    <div
      data-aos="fade-up"
      data-aos-delay="50"
      data-aos-duration="1000"
      data-aos-easing="ease-in-out"
      data-aos-mirror="true"
      data-aos-once="false"
      data-aos-anchor-placement="top-center"
    >
      <base-section-heading color="grey lighten-2" title="Kontak Kami" />
      <v-container class="text-justify">
        <v-row>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-avatar size="80">
                <v-icon class="lighten-1" color="#114e87" size="60">
                  mdi-map-marker-outline
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                Treasury Tower Lantai 56 Unit C-1, District 8 @SCBD, 
Kel. Senayan, Kec. Kebayoran Baru, Kota Adm. Jakarta Selatan, Provinsi DKI Jakarta,
Kode Pos: 12190
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" md="6">
            <v-list-item>
              <v-list-item-avatar size="80">
                <v-icon class="lighten-1" color="#114e87" size="60">
                  mdi-phone-in-talk-outline
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content> (021) 302-12-123 </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-avatar size="80">
                <v-icon class="lighten-1" color="#114e87" size="60">
                  mdi-email-outline
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                cs@orionpenjaminan.co.id</v-list-item-content
              >
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </base-section>
</template>
<script>
export default {
  name: "SectionContactUs",
};
</script>
